///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25rem;

		li {
			padding-left: 0.25rem;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1rem;

		li {
			padding-left: 0.5rem;
		}

		&.divided {
			list-style: none;
			padding-left: 0;

			li {
				border-top: solid 1px;
				padding: 0.5rem 0;

				&:first-child {
					border-top: 0;
					padding-top: 0;
				}
			}
		}
	}

	dl {
		margin: 0 0 _size(element-margin) 0;

		dt {
			display: block;
			font-weight: _font(weight-bold);
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		dd {
			margin-left: _size(element-margin);
		}
	}

	@mixin color-list($p: null) {
		ul {
			&.divided {
				li {
					border-top-color: _palette($p, border);
				}
			}

			&.icons {
				li {
					a.icon {
						&:hover {
							&:before {
								color: _palette($p, accent);
							}
						}
					}
				}

				&.alt {
					li {
						.icon {
							&:before {
								box-shadow: inset 0 0 0 2px _palette($p, border);
							}
						}

						a.icon {
							&:hover {
								&:before {
									box-shadow: inset 0 0 0 2px _palette($p, accent);
								}
							}
						}
					}
				}
			}
		}
	}

	@include color-list;