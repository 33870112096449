///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

.icon {
  @include icon;
  border-bottom: none;
  position: relative;

  > .label {
    display: none;
  }

  &:before {
    line-height: inherit;
  }

  &.solid {
    &:before {
      font-weight: 900;
    }
  }

  &.brands {
    &:before {
      font-family: 'Font Awesome 5 Brands';
    }
  }
}