///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

@import "../libs/breakpoints";
@import "../libs/functions";
/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 _size(element-margin) 0;
  width: 100%;

  tbody {
    tr {
      border: solid 1px;
      border-left: 0;
      border-right: 0;
    }
  }

  td {
    padding: 0.75rem 0.75rem;
  }

  th {
    font-family: _font(family-heading);
    font-size: 0.8rem;
    font-weight: _font(weight-heading);
    letter-spacing: 0.075em;
    line-height: 1.5;
    padding: 0 0.75rem 0.75rem 0.75rem;
    text-align: left;
    text-transform: uppercase;

    @include breakpoint('<=md') {
      font-size: 0.9rem;
    }
  }

  thead {
    border-bottom: solid 2px;
  }

  tfoot {
    border-top: solid 2px;
  }

  &.alt {
    border-collapse: separate;

    tbody {
      tr {
        td {
          border: solid 1px;
          border-left-width: 0;
          border-top-width: 0;

          &:first-child {
            border-left-width: 1px;
          }
        }

        &:first-child {
          td {
            border-top-width: 1px;
          }
        }
      }
    }

    thead {
      border-bottom: 0;
    }

    tfoot {
      border-top: 0;
    }
  }
}

@mixin color-table($p: null) {
  table {
    tbody {
      tr {
        border-color: _palette($p, border);

        &:nth-child(2n + 1) {
          background-color: _palette($p, border-bg);
        }
      }
    }

    th {
      color: _palette($p, fg-bold);
    }

    thead {
      border-bottom-color: _palette($p, border);
    }

    tfoot {
      border-top-color: _palette($p, border);
    }

    &.alt {
      tbody {
        tr {
          td {
            border-color: _palette($p, border);
          }
        }
      }
    }
  }
}

@include color-table;