///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';
@import '../libs/vendor';
@import '../libs/breakpoints';
@import '../libs/html-grid';
@import '../libs/fixed-grid';
@import "../libs/color";

/* Footer */

#footer {
  @include color(alt);
  @include vendor('display', 'flex');
  background-color: _palette(alt, bg);
  color: _palette(fg-light);
  cursor: default;
  position: relative;
  margin: 0 auto;
  width: calc(100% - #{_size(padding) * 2});
  max-width: _size(wrapper);
  z-index: 2;

  > section {
    @include vendor('flex-basis', '50%');
    @include vendor('flex-grow', '1');
    @include vendor('flex-shrink', '1');
    @include padding((_size(padding) * 2), (_size(padding) * 2));
    border-left: solid 2px _palette(alt, border);

    &:first-child {
      border-left: 0;
    }

    &.split {
      @include vendor('display', 'flex');
      @include vendor('flex-direction', 'column');
      padding: 0;

      > section {
        @include padding((_size(padding) * 2) - 1, (_size(padding) * 2));
        border-top: solid 2px _palette(alt, border);

        &:first-child {
          @include padding((_size(padding) * 2) - 1, (_size(padding) * 2), (_size(padding), 0, 0, 0));
          border-top: 0;
        }

        &:last-child {
          @include padding((_size(padding) * 2) - 1, (_size(padding) * 2), (0, 0, _size(padding), 0));
        }
      }

      &.contact {
        > section {
          @include vendor('display', 'flex');
          @include vendor('align-items', 'center');
          padding: (_size(padding) * 1.575) (_size(padding) * 2);

          > * {
            margin-bottom: 0;
          }

          > :first-child {
            @include vendor('flex-shrink', '0');
            @include vendor('flex-grow', '0');
            width: 6rem;
          }

          > :last-child {
            @include vendor('flex-shrink', '1');
            @include vendor('flex-grow', '1');
          }

          &:first-child {
            padding: (_size(padding) * 2) (_size(padding) * 2) ((_size(padding) * 2) - 1) (_size(padding) * 2);
          }

          &:last-child {
            padding: ((_size(padding) * 2) - 1) (_size(padding) * 2) (_size(padding) * 2) (_size(padding) * 2);
          }

          &.alt {
            @include vendor('align-items', 'flex-start');

            > :last-child {
              margin-top: -0.325rem;
            }
          }
        }
      }
    }
  }

  form label,
  h3,
  p {
    font-size: 0.8rem;
  }

  @include breakpoint('<=md') {
    display: block;

    > section {
      border-top: solid 2px _palette(alt, border);

      &:first-child {
        border-top: 0;
      }

      &.split {
        > section {
          @include padding((_size(padding) * 2), (_size(padding) * 2));

          &:first-child {
            @include padding((_size(padding) * 2), (_size(padding) * 2));
          }

          &:last-child {
            @include padding((_size(padding) * 2), (_size(padding) * 2));
          }
        }

        &.contact {
          > section {
            padding: (_size(padding) * 2);

            &:first-child {
              padding: (_size(padding) * 2);
            }

            &:last-child {
              padding: (_size(padding) * 2);
            }
          }
        }
      }
    }

    form label,
    h3,
    p {
      font-size: 0.9rem;
    }
  }

  @include breakpoint('<=sm') {
    > section {
      @include padding((_size(padding) * 1), (_size(padding) * 1));

      &.split {
        > section {
          @include padding((_size(padding) * 1), (_size(padding) * 1));

          &:first-child {
            @include padding((_size(padding) * 1), (_size(padding) * 1));
          }

          &:last-child {
            @include padding((_size(padding) * 1), (_size(padding) * 1));
          }
        }

        &.contact {
          > section {
            padding: (_size(padding) * 1);

            &:first-child {
              padding: (_size(padding) * 1);
            }

            &:last-child {
              padding: (_size(padding) * 1);
            }
          }
        }
      }
    }
  }

  @include breakpoint('<=sm') {
    width: 100%;
  }
}

#copyright {
  @include color-typography(invert);
  position: relative;
  color: transparentize(_palette(invert, fg), 0.75);
  cursor: default;
  font-family: _font(family-heading), sans-serif;
  font-size: 0.8rem;
  font-weight: _font(weight-heading);
  letter-spacing: 0.075em;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  margin: (_size(padding) * 2) auto (_size(padding) * 4) auto;
  width: calc(100% - #{_size(padding) * 2});
  max-width: _size(wrapper);
  z-index: 2;

  a {
    color: inherit;
    border-bottom-color: inherit;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      border-left: solid 2px;
      display: inline-block;
      line-height: 1;
      margin-left: 1rem;
      padding-left: 1rem;

      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  @include breakpoint('<=lg') {
    margin: (_size(padding) * 2) auto;
  }

  @include breakpoint('<=sm') {
    ul {
      li {
        border-left: 0;
        margin: 1rem 0 0 0;
        padding-left: 0;
        display: block;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

#footerLinks {
  @extend #copyright;
  margin: 1.5rem auto -2rem auto;
}