///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Row */
@import "../libs/breakpoints";
@import "../libs/html-grid";

.row {
  @include html-grid(1.5rem);

  @include breakpoint('<=xl') {
    @include html-grid(1.5rem, 'xl');
  }

  @include breakpoint('<=lg') {
    @include html-grid(1.5rem, 'lg');
  }

  @include breakpoint('<=md') {
    @include html-grid(1.5rem, 'md');
  }

  @include breakpoint('<=sm') {
    @include html-grid(1.5rem, 'sm');
  }

  @include breakpoint('<=xs') {
    @include html-grid(1.5rem, 'xs');
  }
}