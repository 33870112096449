///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

@import "../libs/vendor";
@import "../libs/functions";
@import "../libs/breakpoints";
@import "../libs/mixins";

/* Form */

form {
  margin: 0 0 _size(element-margin) 0;

  > :last-child {
    margin-bottom: 0;
  }

  > .fields {
    $gutter: (_size(element-margin) * 0.75);

    @include vendor('display', 'flex');
    @include vendor('flex-wrap', 'wrap');
    width: calc(100% + #{$gutter * 2});
    margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

    > .field {
      @include vendor('flex-grow', '0');
      @include vendor('flex-shrink', '0');
      padding: $gutter 0 0 $gutter;
      width: calc(100% - #{$gutter * 1});

      &.half {
        width: calc(50% - #{$gutter * 0.5});
      }

      &.third {
        width: calc(#{100% / 3} - #{$gutter * (1 / 3)});
      }

      &.quarter {
        width: calc(25% - #{$gutter * 0.25});
      }
    }
  }

  @include breakpoint('<=sm') {
    > .fields {
      $gutter: (_size(element-margin) * 0.75);

      width: calc(100% + #{$gutter * 2});
      margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

      > .field {
        padding: $gutter 0 0 $gutter;
        width: calc(100% - #{$gutter * 1});

        &.half {
          width: calc(100% - #{$gutter * 1});
        }

        &.third {
          width: calc(100% - #{$gutter * 1});
        }

        &.quarter {
          width: calc(100% - #{$gutter * 1});
        }
      }
    }
  }
}

label {
  display: block;
  font-family: _font(family-heading), sans-serif;
  font-weight: _font(weight-heading);
  line-height: 1.5;
  letter-spacing: 0.075em;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin: 0 0 (_size(element-margin) * 0.375) 0;

  @include breakpoint('<=md') {
    font-size: 0.9rem;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
  @include vendor('appearance', 'none');
  background: transparent;
  border-radius: 0;
  border: solid 2px;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1rem;
  text-decoration: none;
  width: 100%;

  &:invalid {
    box-shadow: none;
  }
}

select {
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: _size(element-height);
  padding-right: _size(element-height);
  text-overflow: ellipsis;

  &:focus {
    &::-ms-value {
      background-color: transparent;
    }
  }

  &::-ms-expand {
    display: none;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: _size(element-height);
}

textarea {
  padding: 0.75rem 1rem;
}

input[type="checkbox"],
input[type="radio"], {
  @include vendor('appearance', 'none');
  display: block;
  float: left;
  margin-right: -2rem;
  opacity: 0;
  width: 1rem;
  z-index: -1;

  & + label {
    @include icon(false, solid);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    letter-spacing: 0;
    font-family: _font(family), sans-serif;
    text-transform: none;
    font-weight: _font(weight);
    padding-left: (_size(element-height) * 0.6) + 1rem;
    padding-right: 1rem;
    position: relative;

    &:before {
      border-radius: 0;
      border: solid 2px;
      content: '';
      display: inline-block;
      font-size: 0.8rem;
      height: (_size(element-height) * 0.6);
      left: 0;
      line-height: (_size(element-height) * 0.55);
      position: absolute;
      text-align: center;
      top: -0.125rem;
      width: (_size(element-height) * 0.6);
    }
  }

  &:checked + label {
    &:before {
      content: '\f00c';
    }
  }
}

input[type="checkbox"] {
  & + label {
    &:before {
      border-radius: 0;
    }
  }
}

input[type="radio"] {
  & + label {
    &:before {
      border-radius: 100%;
    }
  }
}

::-webkit-input-placeholder {
  opacity: 1.0;
}

:-moz-placeholder {
  opacity: 1.0;
}

::-moz-placeholder {
  opacity: 1.0;
}

:-ms-input-placeholder {
  opacity: 1.0;
}

@mixin color-form($p: null) {
  label {
    color: _palette($p, fg-bold);
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  select,
  textarea {
    border-color: _palette($p, border);

    &:focus {
      border-color: _palette($p, accent);
    }
  }

  select {
    background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'><path d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='#{_palette($p, border)}' /></svg>");

    option {
      background-color: _palette($p, bg);
      color: _palette($p, fg);
    }
  }

  .select-wrapper {
    &:before {
      color: _palette($p, border);
    }
  }

  input[type="checkbox"],
  input[type="radio"], {
    & + label {
      color: _palette($p, fg);

      &:before {
        border-color: _palette($p, border);
      }
    }

    &:checked + label {
      &:before {
        background-color: _palette($p, fg-bold);
        border-color: _palette($p, fg-bold);
        color: _palette($p, bg);
      }
    }

    &:focus + label {
      &:before {
        border-color: _palette($p, accent);
      }
    }
  }

  ::-webkit-input-placeholder {
    color: _palette($p, fg-light) !important;
  }

  :-moz-placeholder {
    color: _palette($p, fg-light) !important;
  }

  ::-moz-placeholder {
    color: _palette($p, fg-light) !important;
  }

  :-ms-input-placeholder {
    color: _palette($p, fg-light) !important;
  }

  .formerize-placeholder {
    color: _palette($p, fg-light) !important;
  }
}

@include color-form;