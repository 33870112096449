///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Section/Article */
@import "../libs/breakpoints";
@import "../libs/functions";

section, article {
  &.special {
    text-align: center;
  }
}

header {
  cursor: default;

  > .date {
    display: block;
    font-size: 0.8rem;
    height: 1;
    margin: 0 0 (_size(element-margin) * 0.5) 0;
    position: relative;
  }

  > p {
    font-style: italic;
  }

  > h1 + p {
    font-size: 1.1rem;
    margin-top: -0.5rem;
    line-height: 2;
  }

  > h2 + p {
    font-size: 1rem;
    margin-top: -0.75rem;
  }

  > h3 + p {
    font-size: 0.9rem;
    margin-top: -0.75rem;
  }

  > h4 + p {
    font-size: 0.8rem;
    margin-top: -0.75rem;
  }

  &.major {
    margin: 0 0 (_size(element-margin) * 2) 0;
    text-align: center;

    > :last-child {
      margin-bottom: 0;
    }

    > p {
      margin-top: 0;
      text-align: center;
    }

    > .date {
      font-size: 1rem;
      margin: 0 0 (_size(element-margin) * 2) 0;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: calc(50% - 6rem);
        border-top: solid 2px;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }

  @include breakpoint('<=md') {
    br {
      display: none;
    }
  }

  @include breakpoint('<=sm') {
    &.major {
      margin: 0 0 _size(element-margin) 0;
    }
  }
}

@mixin color-section($p: null) {
  header {
    &.major {
      .date {
        &:before, &:after {
          border-top-color: _palette($p, border);
        }
      }
    }
  }
}

@include color-section;