///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			padding: 0 0.5rem 0 0;
			vertical-align: middle;

			&:last-child {
				padding-right: 0;
			}

			.icon {
				&:before {
					width: 2.25rem;
					height: 2.25rem;
					line-height: 2.25rem;
					display: inline-block;
					text-align: center;
					border-radius: 100%;
					font-size: 1.25rem;
				}
			}
		}

		&.alt {
			li {
				.icon {
					&:before {
						@include vendor('transition', (
							'color #{_duration(transition)} ease-in-out',
							'background-color #{_duration(transition)} ease-in-out',
							'border-color #{_duration(transition)} ease-in-out',
							'box-shadow #{_duration(transition)} ease-in-out'
						));

						font-size: 1rem;
					}
				}
			}
		}
	}