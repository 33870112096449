///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///
@import "../libs/color";
@import "../libs/mixins";
/* Nav Panel */

#navPanelToggle {
  @include icon(false, solid);
  //@include vendor('transition', (
  //        'color #{_duration(transition)} ease-in-out',
  //        'background-color #{_duration(transition)} ease-in-out',
  //        'box-shadow #{_duration(transition)} ease-in-out'
  //));
  display: none;
  position: fixed;
  top: 0.75rem;
  right: 0.75rem;
  border: 0;
  color: _palette(invert, fg-bold);
  font-family: _font(family-heading), sans-serif;
  font-size: 0.9rem;
  font-weight: _font(weight-heading);
  letter-spacing: 0.075em;
  padding: 0.375rem 1.25rem;
  text-transform: uppercase;
  z-index: _misc(z-index-base) + 1;
  cursor: pointer;

  &.alt {
    background-color: transparentize(_palette(bg), 0.125);
    box-shadow: 0 0.125rem 0.75rem 0 transparentize(_palette(invert, bg), 0.75);
    color: _palette(fg-bold);

    &:hover {
      background-color: _palette(bg);
    }
  }

  @include breakpoint('<=md') {
    display: block;
  }

  @include breakpoint('<=sm') {
    font-size: 0.8rem;
    padding: 0.25rem 1rem;
  }
}

.linksNavPanel {
  border-top: solid 2px _palette(border) !important;

  border-bottom: 0;
  display: block;
  font-family: _font(family-heading), sans-serif;
  font-size: 0.9rem;
  font-weight: _font(weight-heading);
  letter-spacing: 0.075em;
  //padding: 0.5rem 0;
  text-transform: uppercase;

  &:first-child {
    border-top: 0 !important;
  }
}

.closeNavPanel {
  @include icon(false, solid);
  // @include vendor('transition', 'color #{_duration(transition)} ease-in-out');
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: 0;
  color: _palette(fg-light);
  cursor: pointer;
  display: block;
  height: 3.25rem;
  line-height: 3.25rem;
  padding-right: 1.25rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  vertical-align: middle;
  width: 7rem;

  &:hover {
    color: _palette(fg-bold);
  }

  @include breakpoint('<=sm') {
    height: 4rem;
    line-height: 4rem;
  }

}

.navPanel {
  //@include vendor('transition', ('transform #{_duration(menu)} ease', 'box-shadow #{_duration(menu)} ease', 'visibility #{_duration(menu)}'));
  -webkit-overflow-scrolling: touch;
  background: _palette(bg);
  display: block;
  color: _palette(fg-bold);
  height: 100%;
  // max-width: 80%;
  overflow-y: auto;
  padding: 3rem 2rem;
  position: relative;
  //right: 0;
  //top: 0;
  visibility: visible;
  width: 20rem;
  // z-index: _misc(z-index-base) + 2;
  // @include vendor('transform', 'translateX(0)');
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);

  @include breakpoint('<=sm') {
    padding: 2.5rem 1.75rem;
  }
}

@include breakpoint('<=md') {
  body.is-navPanel-visible {
    #wrapper {
      opacity: 0.5;
    }

    #navPanel {
      @include vendor('transform', 'translateX(0)');
      box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
      visibility: visible;
    }
  }
}